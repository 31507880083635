// chains/chains.ts
import { rinkeby } from "wagmi/chains";
var goerli = {
  id: 5,
  name: "Goerli",
  network: "goerli",
  nativeCurrency: {
    name: "Goerli Ether",
    symbol: "ETH",
    decimals: 18
  },
  rpcUrls: {
    default: "https://eth-goerli.api.onfinality.io/public",
    public: "https://eth-goerli.api.onfinality.io/public"
  },
  blockExplorers: {
    etherscan: {
      name: "Etherscan",
      url: "https://goerli.etherscan.io"
    },
    default: {
      name: "Etherscan",
      url: "https://goerli.etherscan.io"
    }
  },
  ens: {
    address: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e"
  },
  multicall: {
    address: "0xca11bde05977b3631167028862be2a173976ca11",
    blockCreated: 6507670
  },
  testnet: true
};
var avalandche = {
  id: 43114,
  name: "Avalanche C-Chain",
  network: "avalanche",
  rpcUrls: {
    default: "https://rpc.ankr.com/avalanche"
  },
  nativeCurrency: { name: "Avalanche", symbol: "AVAX", decimals: 18 },
  blockExplorers: {
    default: {
      name: "snowtrace",
      url: "https://snowtrace.io/"
    }
  }
};
var avalandcheFuji = {
  id: 43113,
  name: "Avalanche Fuji",
  network: "avalanche-fuji",
  rpcUrls: {
    default: "https://rpc.ankr.com/avalanche_fuji"
  },
  nativeCurrency: { name: "Avalanche", symbol: "AVAX", decimals: 18 },
  blockExplorers: {
    default: {
      name: "snowtrace",
      url: "https://testnet.snowtrace.io/"
    }
  },
  testnet: true
};
var fantomOpera = {
  id: 250,
  name: "Fantom Opera",
  network: "fantom",
  nativeCurrency: { name: "Fantom", symbol: "FTM", decimals: 18 },
  rpcUrls: {
    default: "https://rpc.ftm.tools"
  },
  blockExplorers: {
    default: {
      name: "FTMScan",
      url: "https://ftmscan.com"
    }
  }
};
var fantomTestnet = {
  id: 4002,
  name: "Fantom Testnet",
  network: "fantom-testnet",
  nativeCurrency: { name: "Fantom", symbol: "FTM", decimals: 18 },
  rpcUrls: {
    default: "https://rpc.testnet.fantom.network"
  },
  blockExplorers: {
    default: {
      name: "FTMScan",
      url: "https://testnet.ftmscan.com"
    }
  },
  testnet: true
};
var bscExplorer = { name: "BscScan", url: "https://bscscan.com" };
var bsc = {
  id: 56,
  name: "BNB Smart Chain",
  network: "bsc",
  rpcUrls: {
    public: "https://bsc-dataseed1.defibit.io",
    default: "https://bsc-dataseed1.defibit.io"
  },
  blockExplorers: {
    default: bscExplorer,
    etherscan: bscExplorer
  },
  nativeCurrency: {
    name: "Binance Chain Native Token",
    symbol: "BNB",
    decimals: 18
  },
  multicall: {
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    blockCreated: 15921452
  }
};
var bscTest = {
  id: 97,
  name: "BNB Smart Chain Testnet",
  network: "bsc-testnet",
  nativeCurrency: {
    decimals: 18,
    name: "Binance Chain Native Token",
    symbol: "tBNB"
  },
  rpcUrls: {
    public: "https://bsc-testnet.public.blastapi.io",
    default: "https://bsc-testnet.public.blastapi.io"
  },
  blockExplorers: {
    default: { name: "BscScan", url: "https://testnet.bscscan.com" }
  },
  multicall: {
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    blockCreated: 17422483
  },
  testnet: true
};
var telegramX = {
  id: 8989,
  name: "telegramX",
  network: "telegramX",
  nativeCurrency: {
    decimals: 18,
    name: "telegramX Native Token",
    symbol: "TX"
  },
  rpcUrls: {
    public: "https://as.txpocket.link",
    default: "https://as.txpocket.link"
  },
  blockExplorers: {
    default: { name: "telegramXLink", url: "https://www.txpocket.link" }
  },
  multicall: {
    address: "0x7D785c7A7b825706a827E5E8d965d1E58574e1c9",
    blockCreated: 1e7
  }
};
var mainnet = {
  id: 1,
  name: "Ethereum",
  network: "homestead",
  nativeCurrency: {
    name: "Ether",
    symbol: "ETH",
    decimals: 18
  },
  rpcUrls: {
    default: "https://web3.mytokenpocket.vip",
    public: "https://web3.mytokenpocket.vip"
  },
  blockExplorers: {
    etherscan: {
      name: "Etherscan",
      url: "https://etherscan.io"
    },
    default: {
      name: "Etherscan",
      url: "https://etherscan.io"
    }
  },
  ens: {
    address: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e"
  },
  multicall: {
    address: "0xca11bde05977b3631167028862be2a173976ca11",
    blockCreated: 14353601
  }
};
var base = {
  id: 8453,
  name: "Base",
  network: "base",
  nativeCurrency: {
    name: "Ether",
    symbol: "ETH",
    decimals: 18
  },
  rpcUrls: {
    default: "https://developer-access-mainnet.base.org",
    public: "https://developer-access-mainnet.base.org"
  },
  blockExplorers: {
    etherscan: {
      name: "Basescan",
      url: "https://basescan.org"
    },
    default: {
      name: "Basescan",
      url: "https://basescan.org"
    }
  },
  ens: {
    address: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e"
  },
  multicall: {
    address: "0x06D10cb08e79D9335905fCD64e0766F6e191C8a1",
    blockCreated: 1e6
  }
};
var xlayer = {
  id: 196,
  name: "X Layer",
  network: "x_layer",
  nativeCurrency: {
    name: "OKB",
    symbol: "OKB",
    decimals: 18
  },
  rpcUrls: {
    default: "https://endpoints.omniatech.io/v1/xlayer/mainnet/public",
    public: "https://endpoints.omniatech.io/v1/xlayer/mainnet/public"
  },
  blockExplorers: {
    etherscan: {
      name: "OKLINK",
      url: "https://okx.com/explorer/xlayer"
    },
    default: {
      name: "OKLINK",
      url: "https://okx.com/explorer/xlayer"
    }
  },
  ens: {
    address: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e"
  },
  multicall: {
    address: "0x06D10cb08e79D9335905fCD64e0766F6e191C8a1",
    blockCreated: 1e6
  }
};
var goerliBase = {
  id: 84531,
  name: "Goerli Base",
  network: "goerliBase",
  nativeCurrency: {
    name: "Ether",
    symbol: "ETH",
    decimals: 18
  },
  rpcUrls: {
    default: "https://goerli.base.org",
    public: "https://goerli.base.org"
  },
  blockExplorers: {
    etherscan: {
      name: "Basescan",
      url: "https://basescan.org"
    },
    default: {
      name: "Basescan",
      url: "https://basescan.org"
    }
  },
  ens: {
    address: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e"
  },
  multicall: {
    address: "0x506351D64b48811fE153B2af52B798Fb6CBaB7ab",
    blockCreated: 1
  },
  testnet: true
};
var bit = {
  id: 198,
  name: "BitChain",
  network: "bit",
  nativeCurrency: {
    name: "BTCs",
    symbol: "BTCs",
    decimals: 18
  },
  rpcUrls: {
    default: "https://fa888shun666fa888.bitchain.biz",
    public: "https://fa888shun666fa888.bitchain.biz"
  },
  blockExplorers: {
    default: {
      name: "BitChain",
      url: "https://explorer.bitchain.biz"
    }
  },
  ens: {
    address: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e"
  },
  multicall: {
    address: "0x432a5B82F7dCB5686231C019c4FB8AcDE0724938",
    blockCreated: 1
  }
};
export {
  avalandche,
  avalandcheFuji,
  base,
  bit,
  bsc,
  bscTest,
  fantomOpera,
  fantomTestnet,
  goerli,
  goerliBase,
  mainnet,
  rinkeby,
  telegramX,
  xlayer
};
