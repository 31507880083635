import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  // {
  //   label: '枫叶科技',
  //   items: [
  //     {
  //       label: '枫叶预售平台',
  //       href: 'https://www.maplesales.org/',
  //     },
  //     {
  //       label: '枫叶NFT平台',
  //       href: 'https://nft.maplesales.org/',
  //     },
  //     {
  //       label: '枫叶保险平台',
  //       href: 'https://insurance.maplesales.org/',
  //     },
  //     {
  //       label: '商务合作',
  //       href: 'https://t.me/FYKJ88888',
  //     },
  //     {
  //       label: '技术支持',
  //       href: 'https://t.me//maple_8188',
  //     },
  //   ],
  // },
  {
    label: 'About',
    items: [
      {
        label: 'Explorer',
        href: 'https://explorer.bitchain.biz/',
      },
      // {
      //   label: 'Bridge',
      //   href: 'https://newbridge.bitchain.biz/',
      // },
      // {
      //   label: 'Guidebook',
      //   href: 'https://introduce.5bitchain.com/',
      // },
      // {
      //   label: "TX SWAP V1",
      //   href: "https://v1.txpocket.link/",
      // },
    ],
  },
]
